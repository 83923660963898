"use strict";
/* eslint-disable-next-line no-unused-vars */
function __export(m) {
/* eslint-disable-next-line no-prototype-builtins */
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var Voting = require('./Voting');
exports.Voting = Voting;
exports.packageId = '5ea1b66908dcd5d9a7a2e7417b1c1a22a1ed31a424113939d3478c2e94172e73';
